import React from 'react'

import { useFirebase } from 'react-redux-firebase'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

import { firebaseSignInConfg } from '../utils/firebase'

const Login = () => {
  const firebase = useFirebase()

  return (
    <StyledFirebaseAuth
      uiConfig={firebaseSignInConfg}
      firebaseAuth={firebase.auth()}
    />
  )
}

export default Login
