export default {
  FIREBASE: {
    apiKey: 'AIzaSyAZbxtHC7AuCUlIJxoeaatOmkmSVh1uCt8',
    authDomain: 'screencrab-3759a.firebaseapp.com',
    databaseURL: 'https://screencrab-3759a.firebaseio.com',
    projectId: 'screencrab-3759a',
    storageBucket: 'screencrab-3759a.appspot.com',
    messagingSenderId: '617978035269',
    appId: '1:617978035269:web:f2380826dc82952741772c',
    measurementId: 'G-YDJ2K22ZE0',
  },
}
