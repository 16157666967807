import { RootState } from '../../types/modules'

import { createSelector } from 'reselect'

import * as R from 'ramda'
import { ExtendedFirebaseInstance } from 'react-redux-firebase'

const getRoot: (state: RootState) => ExtendedFirebaseInstance = R.prop(
  'firebase'
)

export const getAuth = createSelector(getRoot, R.prop('auth'))
