import defaultConfig from './default'
import devConfig from './development'
import prodConfig from './production'

const IS_PRODUCTION = process.env.NODE_ENV === 'production'
const IS_DEV = !IS_PRODUCTION

export default {
  ...defaultConfig,
  ...(IS_PRODUCTION ? prodConfig : devConfig),
  IS_PRODUCTION,
  IS_DEV,
}
