import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'

import config from './config'
import { getAuth } from './modules/firebase/selectors'

import PrivateRoute from './components/PrivateRoute'
import Login from './layouts/Login'

const Homepage = React.lazy(() => import('./layouts/Homepage'))
const Dashboard = React.lazy(() => import('./layouts/Dashboard'))

function App() {
  const auth = useSelector(getAuth)

  if (config.IS_PRODUCTION)
    return (
      <span role="img" aria-label="crab emoji">
        🦀
      </span>
    )

  if (!isLoaded(auth)) return <p>loading!</p>

  return (
    <React.Suspense fallback={<div>loading...</div>}>
      <Switch>
        <Route path="/login">
          {/* TODO: Use a modal */}
          <Login />
        </Route>

        <PrivateRoute path="/organization/:orgSlug">
          <Dashboard />
        </PrivateRoute>

        <Route>
          <Homepage />
        </Route>
      </Switch>
    </React.Suspense>
  )
}

export default App
