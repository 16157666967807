import firebase from 'firebase/app'
import { Store } from 'redux'

import 'firebase/auth'

import config from '../config'

firebase.initializeApp(config.FIREBASE)

const firestoreProviderConfig = {
  userProfile: 'users',
}

export const firebaseSignInConfg = {
  signInFlow: 'popup',
  signInSuccessUrl: '/organization/orgSlug', // Returned from sign-in, or chained immediately post. Either way returned value should be last viewed org.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
}

export const createFirestoreProviderProps = (store: Store) => ({
  firebase,
  config: firestoreProviderConfig,
  dispatch: store.dispatch,
})
