import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter } from 'react-router-dom'

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'

import { createFirestoreProviderProps } from './utils/firebase'
import { rootReducer } from './modules'
import App from './App'

import './index.css'

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })

const store = createStore(rootReducer, composeEnhancers())

const firestoreProviderProps = createFirestoreProviderProps(store)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...firestoreProviderProps}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
